.imageDraggerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;



}

.imageInputDragger {
  flex: 1;



}

.productImageInputWrapper {
  display: flex;
  align-items: stretch;

  & > span {
    flex: 1;
  }

  .productImage {
    flex-shrink: 0;
    margin-right: 8px;
  }
}