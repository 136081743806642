.rootContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  .title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;
  }

  .btnWrapper {
    display: flex;
    justify-content: center;
  }

  .input {
    margin-bottom: 16px;
    margin-top: 0;
  }

}