.topWrapper{

}

.optionsWrapper {
  margin-top: 8px;

  .missingAccountStripeInfo {
    color: red;
  }

}



.saveWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}