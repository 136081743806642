.wrapper {

  .timeWrapper {
    margin: 0;
    font-weight: bold;
    .start {

    }

    .end {

    }
  }


}