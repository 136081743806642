.deliveryMapDeleteButton {
  $color: #FF1B32;
  border-width: 0;
  background-color: $color;
  color: white;
  padding: 4px 14px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;


  &:hover {
    background-color: darken($color, 12%);
  }
}