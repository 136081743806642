.wrapper {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0,0,0,0.15);
  & > * {

    &:not(:first-child) {
      margin-left: 2px;
    }
    &:not(:last-child) {
      margin-right: 2px;
    }


  }
}