@import "../../scss/colors.scss";


.swippyInput {
  outline: none;
  &:hover {
    border-color: $primary !important;
  }





  &:focus {
    border-color: $primary !important;
  }

  &:disabled {
    background-color: darken(rgba(255,255,255,1), 10%) !important;
  }



  &.hideNumberArrows {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}
