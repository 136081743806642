.authScreenWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center;
    .authScreenTitle{
        font-weight: bold;
        font-size: 2em;
        margin-bottom: 0;
    }

    .authCard {
        min-width: 300px;
    }
}