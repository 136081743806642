.productsListTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .name {
    margin: 0;
  }
}


.listWrapper {
  margin-top: 8px;
}