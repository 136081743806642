@import "../../../scss/responsive-breakpoints";

.menu {
  padding: 0;
  background-color: white;
  border-right: 1px solid rgba(0,0,0,0.18);
  position: fixed;
  height: 100vh;
  overflow-y: auto;


  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      display: none;
      margin-right: 8px;
      opacity: 0.6;
      @include max-width(sm) {
        display: block;
      }
    }
  }

  @include max-width(sm) {
    position: fixed;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    z-index: 3;
    max-width: unset !important;
    min-width: unset !important;
    flex: unset !important;
    &:not(.opened) {
      display: none;
    }
  }




  .logotypeWrapper {
    display: flex;
    //justify-content: center;
    padding: 16px 8px;
    width: 100%;
  }

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .linkBtnWrapper {
    display: flex;
    margin-top: auto;
    padding-top: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }
}