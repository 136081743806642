.wrapper {
  display: flex;
  align-items: center;

  .rightWrapper {
    display: flex;
    flex-direction: column;

    .name {

    }

    .description {
      opacity: 0.7;
      margin: 0;

      font-size: 0.8em;
    }
  }

  .dragHandle {
    display: none;
    margin-right: 4px;
    opacity: 0.7;
    &:hover {
      cursor: move;
    }
  }


  &.hover {
    .dragHandle {
      display: block !important;
    }
  }

}