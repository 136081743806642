.wrapper {
  border-top: 1px solid rgba(0,0,0,0.15);
  padding: 8px;

  .total {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-between;
  }
}