@import "../../../../scss/colors";
.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  &:hover, &.active {
    background-color: #EAEAEA;
  }
  .label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1em;
    font-weight: bold;
    
  }
}