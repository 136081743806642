.titleWithCtaWrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 0;


  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: bold;
      margin-bottom: 0;
    }

  }


  .subTitle {
    margin: 8px 0 0;

  }
}