.wrapper {
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,0.15);

  .productName {
    font-size: 1em;
    margin: 0;
  }

  .rightWrapper {
    display: flex;
    align-items: center;

    & > .price {
      margin-right: 4px;
    }

    & > .delete {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}