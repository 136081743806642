@import "../../scss/colors.scss";
div.swippyInputNumber {
  width: 100%;
  &:hover, &:focus {
    border-color: $primary;
  }

  outline: none;



}


:global {
  .ant-input-number-focused {
    border-color: $primary;
  }
}