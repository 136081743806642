.itemWrapper {


  .clickable {
    padding: 16px;
    &:hover {
      cursor: pointer;
      filter: brightness(90%);
    }
  }

  display: flex;
  align-items: stretch;

  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 16px;
  overflow: hidden;
  background-color: white;
  justify-content: space-between;

  .itemMenuDots {
    font-size: 24px;
  }

  .rightContainer {
    flex: 1;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .actionMenu {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0,0,0,0.15);
  }




}