.map {
  position: relative;
  height: 500px !important;
}

.label {
  font-size: 16px;
  //font-weight: bold;
}

.saveBtnWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}
