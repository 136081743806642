.breadcrumbWrapper {
  display: flex;
  align-items: center;

  .separator {
    opacity: 0.4;
    margin-left: 4px;
    margin-right: 4px;
  }

  .breadcrumbItem {
    opacity: 0.7;
    &.clickable {
      opacity: 0.8;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  .label {
    line-height: 1em;
  }

  line-height: 1em;
}