@import "../../../scss/responsive-breakpoints";
@import "../../../scss/colors";
.swippyHeader {

  display: flex;
  align-items: center;
  justify-content: space-between;



  @include max-width(sm) {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .menuWrapper{
    display: none;

    color: $primary;
    @include max-width(sm) {
      display: block;
    }
  }

  .headerContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include max-width(sm) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

    }
  }

  background-color: white;
  border-bottom: 1px solid rgba(0,0,0,0.15);





  right: 0;
  .currentRistopub {
    font-size: 1em;
    line-height: 1em;
  }
  padding-left: 16px;
  padding-right: 16px;

  .leftInfoWrapper {
    display: flex;
    flex-direction: column;
  }

  .rightInfoWrapper {
    line-height: 1em;
    @include max-width(sm) {
      margin-top: 4px;
    }
    .email {
      cursor: pointer;
      opacity: 0.7;
      line-height: 1em;

    }
  }
}