
:global {
  .swippySelect {
      width: 100%;


    &.hasError {
      .ant-select-selector {
        border-color: rgba(248, 113, 113, 1);
      }
    }

    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector
    {
      border-color: #2195d8;
    }


  }
}