.mapWrapper {
  width: 100%;
  height: 100vh;
  & > div {
    height: 100%
  }
}


.searchInput {
  height: 32px;
  top: 12px !important;
  left: 12px !important;
  min-width: 200px;
}