:global {
  .swippyTabs {

    font-size: 1em;

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #2195d8;
    }

    .ant-tabs-tab {
      font-size: 1em;
    }

    .ant-tabs-content-holder {
      font-size: 1em;
      .ant-form {
        font-size: 1em;

        .ant-row {
          font-size: 1em;
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: #2195d8;
    }

    .ant-tabs-tab:hover {
      color: #2195d8;
    }
  }
}