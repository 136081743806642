
@import "../../../../scss/colors";
.topWrapper {
  display: flex;
  align-items: center;

  .topInfo {
    margin-bottom: 0;
  }
  justify-content: space-between;
  .label {
    font-weight: bold;
  }

  .accountEmail {
    font-style: italic;
    margin-left: 4px;
  }

}

.missingInfoTitle {
  color: red;
  font-size: 1em;
  font-weight: bold;
}


.missingInfoWrapper {
  .stripeDashboardLink {
    color: $primary;
    text-decoration: underline;
    &:hover {
      color: darken($primary, 10%);
    }
  }
}