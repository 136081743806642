@import "../../scss/colors";
div.swippyDragger {
  &:hover {
    border-color: $primary !important;
  }
}


:global {
  .ant-upload-list-item-name {
    white-space: normal;
  }
}