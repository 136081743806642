@import "../../../scss/colors";
.loginWithGoogleButton {
  display: flex;
  align-items: center;
  min-height: 45px;
  .googleLogo{
    width: 20px;
    margin-right: 8px;
  }

  &.ant-btn:hover, &.ant-btn:focus {
    border-color: $primary;
    color: $primary;
  }
}