.wrapper {
  display: flex;
  align-items: center;
  .selectWrapper {
    flex: 1;


    &:not(:last-child) {
      margin-right: 4px;
    }
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}