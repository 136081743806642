@import "../../scss/responsive-breakpoints";

.mainLayout {
    font-size: 1em;

    .mainContentLayout {
        &.withSider {
            margin-left: 200px;



            @include max-width(sm) {
                &:not(.siderOpened) {
                    margin-left: 0;
                }
            }
        }
    }

    .content {
        width: 100%;
        position: relative;
        z-index: 0;
        background-color: #ECF1F5;


        @include min-width(md) {
            width: 75%;
        }



        margin-left: auto;
        margin-right: auto;

        display: flex;
        flex-direction: column;
    }
}