.wrapper {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  .loadingWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}