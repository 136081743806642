@import "../../scss/colors";
.wrapper {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0,0,0,0.07);
  background-color: darken(white, 7%);
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    background-color: darken(white, 15%);
  }

  .leftContainer{
    display: flex;
    align-items: center;
  }

  .rightContainer {
    padding: 4px;
    .price {

    }
  }

  cursor: pointer;
  .productImage {
    margin-right: 4px;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: lightgrey;
  }


  .name {
    text-align: center;
    margin: 0;

    .qty{
      color: $primary;
      font-weight: bold;
    }
  }
}