.wrapper {
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: white;


  .mainHeaderWrapper {
    border-bottom: 1px solid rgba(0,0,0,0.15);
    padding: 8px;
  }


  .headerWrapper {


    .topHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;


      .name {
        font-size: 1em;
        margin: 0;
        font-weight: bold;
      }
      .typeBadge {
        color: white;
        margin: 0;
      }


    }



    .header {
      border-bottom: 1px solid rgba(0,0,0,0.15);

      padding: 8px;

      display: flex;
      align-items: center;
      justify-content: space-between;



      .createdAt, .customerPhone {
        margin: 0;
      }
      .customerPhone {

      }

    }
  }








  .orderItemsList {
    & > div:not(:last-child) {
      border-bottom: 1px solid rgba(0,0,0,0.15);
    }
  }


  .notesWrapper {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0,0,0,0.15);
    padding: 8px;
    .label {
      font-weight: bold;
      font-size: 1em;
    }
  }

  .totalWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid rgba(0,0,0,0.15);
    .label {

    }

    .total {

    }
  }
}