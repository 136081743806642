.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  .title {
    margin: 0 4px 0 0;
    font-size: 1em;

  }

  .price {
    margin-left: 4px;
  }
}