



  .productImage {
    flex-shrink: 0;
    margin-right: 8px;
    border-radius: 4px;
  }


  .titleAndPrice {
    display: flex;
    flex-direction: column;

    .productPrice {
      opacity: 0.5;
    }
  }
