.wrapper {

  display: flex;
  flex-direction: column;
  font-size: 1em;
  border-bottom: 1px solid rgba(0,0,0,0.15);

  .bottomHeaderWrapper {
    padding: 8px;
    display: flex;
    flex-direction: column;
  }


  .headerMainWrapper {
    padding: 8px;
    border-bottom: 1px solid rgba(0,0,0,0.15);
  }

  .titleWrapper {

    display: flex;
    align-items: center;
    justify-content: space-between;



    .typeBadge {
      margin: 0;
      color: white;
      border-radius: 4px;
    }
  }
  .rightWrapper {
    display: flex;
    align-items: center;
  }

  .archiveIcon {
    opacity: 0.4;
    margin-left: 4px;
    margin-right: 0;
    cursor: pointer;
  }

  .newTag {
    background-color: red;
    color: white;
    border-radius: 4px;
  }



  .addressRow {
    font-size: 0.8em;
    margin: 0;
    .address {
      font-style: italic;
    }
  }

  .dueDate {
    margin: 0;
    font-size: 0.8em;
    .date {
      text-decoration: underline;
    }

    .minutesLeft {
      opacity: 0.7;
    }

  }

  .title {
    font-weight: bold;
    font-size: 1em;
    margin: 0;
    display: flex;
    align-items: center;
    .inPlaceOrder {
      opacity: 0.8;
      font-size: 0.8em;
      margin: 0 0 0 4px;
    }
  }

  .phone {
    margin: 0;
    font-size: 0.8em;
    opacity: 0.7;
  }

  .status {
    margin: 0;
    font-size: 0.8em;
    opacity: 0.7;
  }

}