
@import "./scss/colors";


@media print {
  .no-print{
    display: none !important;
  }
}

body {
  font-size: 16px;
  p, span {
    font-size: 1em;
  }
}

* {
  font-family: 'Raleway', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ant-modal-confirm-btns {
  button.ant-btn.ant-btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: white;


    &:hover, &:focus, &:active {
      background-color: darken($primary, 10%);
    }
  }


  button.ant-btn:not(.ant-btn-primary) {
    &:hover, &:focus, &:active {
      border-color: $primary;
      color: $primary;
    }
  }
}


