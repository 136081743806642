@import "../../scss/colors";


:global {
  button.swippyButton {


    .ant-btn-loading-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &.ant-btn-ghost:hover, &.ant-btn-ghost:focus {
      border-color: $primary;
      color: $primary;

    }

    &.ant-btn-ghost {
      border-radius: 24px ;
    }

    &.ant-btn-link {
      color: $primary;
      padding: 0;
      height: auto;
      & > span {
        text-decoration: underline;
      }

      &:hover {
        color: darken($primary, 20%);
      }
    }

    &.ant-btn-primary {
      font-weight: bold;
      &:not(:disabled), &:active, &:focus {
        background-color: $primary;
        border-color: $primary;
      }

      border-radius: 24px;
      min-width: 120px;



      &:not(:disabled):hover {
        $pressedPrimary: darken($primary, 12%);
        background-color: $pressedPrimary;
        border-color: $pressedPrimary;
      }
    }


  }
}



