@import "../../../scss/colors";

.wrapper {
  //padding: 12px;
  //background-color: $primary;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    width: 140px;
  }
}