@import "../../scss/colors";

:global {
  .swippyCheckbox {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary;
      border-color: $primary;
    }

    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: transparentize(white, 0.3);
    }


    font-size: 1em;




    &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $primary;
    }

    .ant-checkbox-checked::after {
      border-color: $primary;
    }
  }
}