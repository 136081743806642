.wrapper {
  border-top: 1px solid rgba(0,0,0,0.15);
  padding: 8px;


  .notesTitle {
    opacity: 1;
    font-weight: bold;
    font-size: 1em;
    color: black;
    margin: 0;
  }

  .notes {
    margin: 0;

  }
}