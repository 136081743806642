@import "../scss/colors";

.addProductButton {
  color: $primary;
  font-size: 24px;
  cursor: pointer;

  border-radius: 50%;

  &:hover {
    color: darken($primary, 10%);
  }
}

.searchInput {
  margin-bottom: 4px;
}