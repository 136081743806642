.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .productName {
    margin: 0;
    font-size: 1em;
  }

  .price {
    margin: 0;

  }
}